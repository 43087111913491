import * as React from "react";
import {Routes, Route, Outlet, BrowserRouter, Link} from "react-router-dom";
import Login from '../Login/Login';
import useToken from './useToken';
import {Button, Form, Nav, Navbar} from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from "./../../img/logo.png";
import {useCallback, useEffect, useState} from "react";
import * as Icon from 'react-bootstrap-icons';
import "./App.css";
import Filter from "../Import/Filter";
import ProductValidation from "../Import/ProductValidation";
import useConfig from "./useConfig";

export default function App() {

    const { token, setToken } = useToken();
    const { config, setConfig } = useConfig();
    const [count, setCount] = useState({});
    const [searchFilter, setSearchFilter] = useState({"groupby": true});
    const searchItems = (searchValue) => {
        setSearchFilter(searchValue);
    }


    const reconnectUser = useCallback(
        () => {
            try {
                const credentials = {
                    'client_id' : process.env.REACT_APP_CLIENT_ID,
                    'client_secret' : process.env.REACT_APP_CLIENT_SECRET,
                    'grant_type' : 'refresh_token',
                    'refresh_token' : JSON.parse(localStorage.getItem('refresh_token')),
                };

                fetch(process.env.REACT_APP_SF_URL + 'oauth/v2/token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "host" : "dev-twil.co"
                    },
                    body: JSON.stringify(credentials)
                }).then(data => data.json())
                    .then(data => {
                        if(data.hasOwnProperty('access_token')) {
                            setToken(data);
                            window.location.href = '/';
                        }
                    })
            } catch (err) {
                console.log(err);
                if (err.error !== 'login_required') console.log(err.error);
            }
        },
        [setToken]
    );


    const fetchCount = useCallback(
        (params) => {
            fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/count?' + new URLSearchParams(params).toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "host" : "dev-twil.co",
                    'Authorization': 'Bearer ' + token,
                },
            }).then(res => res.json())
            .then(data =>  {
                if(data.error === "invalid_grant") {
                    console.log("ERREUR FETCH COUNT");
                    reconnectUser();
                } else {
                    setCount(data);
                }
            })
        },
        [reconnectUser, token]
    );

    useEffect(() => {
        try {

            if(token) {
                if(!config) {
                    fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/config', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            "host" : "dev-twil.co",
                            'Authorization': 'Bearer ' + token,
                        },
                    }).then(res => res.json())
                    .then(data =>  {
                        if(data?.status !== false && data?.error !== "invalid_grant") {
                            setConfig(data)
                        }

                    })
                }
                if(Object.keys(count).length === 0) {
                    fetchCount({"groupby" : true});
                }
            }
        } catch (err) {
            console.log(err);
            if (err.error !== 'login_required') console.log(err.error);
        }
    }, [fetchCount, config, token, setConfig, count]);

    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
        <div className="wrapper">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout searchItems={searchItems} filter={searchFilter} token={token} config={config} count={count} fetchCount={fetchCount}/>}>
                        <Route index element={<ProductValidation status={"pending"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="pre-match" element={<ProductValidation status={"pre_match"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="waiting" element={<ProductValidation status={"pending"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="match" element={<ProductValidation status={"matched"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="dematch" element={<ProductValidation status={"unmatched"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="refused" element={<ProductValidation status={"refused"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="pass" element={<ProductValidation status={"pass"} token={token} filter={searchFilter} config={config}/>} />
                        <Route path="*" element={<></>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

function logout() {
    localStorage.clear();
    window.location.href = '/';
}

function Layout(props) {
    const [show, setShow] = useState(false);
    const [pathname, setPathname] = useState(window.location.pathname === "/" ? "/waiting" : window.location.pathname);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
            <Navbar fixed="top" bg="dark" variant="dark" className={"p-3"}>
                <Button variant="outline-secondary" onClick={handleShow}>
                    <Icon.Grid3x3Gap />
                </Button>
                <Navbar.Brand href="/"><img src={logo} alt={'logo'} className={"margin_left_10"}/></Navbar.Brand>
                    <Nav className="me-auto" activeKey={pathname} onSelect={(selectedKey) => setPathname(selectedKey)}>
                        <Nav.Link as={Link} eventKey="/waiting" to="waiting">En attente ({props.count?.countPending})</Nav.Link>
                        <Nav.Link as={Link} eventKey="/pre-match" to="pre-match">Pré-match ({props.count?.countPrematch})</Nav.Link>
                        <Nav.Link className={"hidden-xs"} as={Link} eventKey="/match" to="match">Match ({props.count?.countMatched})</Nav.Link>
                        <Nav.Link className={"hidden-xs"} as={Link} eventKey="/dematch" to="dematch">Dématch ({props.count?.countUnmatched})</Nav.Link>
                        <Nav.Link className={"hidden-xs"} as={Link} eventKey="/refused" to="refused">Rejeté ({props.count?.countRefused})</Nav.Link>
                        <Nav.Link className={"hidden-xs"} as={Link} eventKey="/pass" to="pass">Passé ({props.count?.countPass})</Nav.Link>
                    </Nav>
                    <a className={"text-warning margin_right_10 hidden-xs"} href={"https://thewineilove.notion.site/Espace-Vendeur-cdbcfcc6c3a74b02a4ac5449e6d1613a"} target={"_blank"} rel={"noreferrer"}>
                        <Icon.QuestionCircleFill/>
                    </a>
                    <Form className={"d-flex hidden-xs"}>
                        <Button variant="primary" onClick={logout}>Logout</Button>
                    </Form>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Filtre
                                <Button variant="link" onClick={() => {props.searchItems({});handleClose();props.fetchCount({})}}>Vider les filtres</Button>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Filter searchFilter={props.searchItems}
                                    filter={props.filter}
                                    colors={props.config?.colors}
                                    types={props.config?.types}
                                    shopsPending={props.count?.shopsPending}
                                    closeFilter={handleClose}
                                    fetchCount={props.fetchCount}
                                    token={props.token}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>
            </Navbar>

            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <Outlet />
        </div>
    );
}