import React, {useCallback, useEffect, useState} from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    Button,
    Modal,
    Spinner,
    OverlayTrigger,
    Popover,
    InputGroup,
    Tooltip
} from "react-bootstrap";
import "./import.css";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import * as Icon from 'react-bootstrap-icons';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


function displayStatus(status) {
    let text = "";
    switch (status) {
        case "pending":
        case "pre_match":
            text = "En attente";
            break;
        case "unmatched":
            text = "Dematché";
            break;
        case "refused":
            text = "Refusé";
            break;
        case "pass":
            text = "Passé";
            break;
        default:
            text = "Matché";
    }

    return text;
}

function ReadMore ({ children })  {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    let result;

    if(text && text.length > 150) {
        result = (
            <p className="text">
            {text.length ? "" : ""}
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...voir plus" : " voir moins"}
            </span>
            </p>
        )
    } else {
        result = text;
    }
    return (
       <>
        {result}
       </>
    );
};

function Loader() {
    return(
        <span className={"text-center"}>
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
        </span>
    )
}

function HeaderProductImport(props) {
    const color = props.status !== "pending" ? "text-white" : "text-black";
    let icon;
    let url;
    switch (props.type) {
        case 1:
            url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductwine/" + props.cuvee + "/edit?tl=fr_fr";
            icon = "🍷";
            break;
        case 2:
            url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductspirit/" + props.cuvee + "/edit?tl=fr_fr";
            icon = "🥃";
            break;
        case 3:
            url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductbeer/" + props.cuvee + "/edit?tl=fr_fr";
            icon = "🍺";
            break;
        case 4:
            url = process.env.REACT_APP_SF_URL + "admin/twil/product/productgeneric/" + props.cuvee + "/edit?tl=fr_fr";
            icon = "🚀";
            break;
        default:
            icon = "";
            break;
    }
    return(
        <>
            <div>{props.id ? props.id : props.cuvee ?  <a target={"_blank"} rel={"noreferrer"} className={color} href={url}>Cuvee : {props.cuvee}</a> : "Cuvee : "} {icon}</div>
            <div>{props.type !== 4 ? props.name ? props.name : props.product ? <a target={"_blank"} rel={"noreferrer"} className={color} href={process.env.REACT_APP_SF_URL + "admin/twil/product/product/" + props.product + "/edit?tl=fr_fr"}>Product : {props.product}</a> : "Product : " :  ""}</div>
        </>
    )
}

function ProductDescriptionRow(props) {
    return(
        <div>{props.label} : <span className={props.color}><ReadMore>{props.data}</ReadMore></span></div>
    )
}

function ProductDescription(props) {
    return(
        <>
            {Object.entries(props.description).map((row, index) => (
                <ProductDescriptionRow label={row[0]} data={row[1]['value']} color={row[1]['color']} key={index}/>
            ))}
        </>
    );
}

function ProductImage(props) {
    return(
        <Zoom>
            <img src={props.src} height={200} style={{height: "200px", overflow: "hidden"}}  className={"mb-2"} alt={"produit"}/>
        </Zoom>
    );
}

function SelectStatus(props) {
    const optionsMatch = [
        {value: "fusion_missing", label: "Remplir les vides"},
        {value: "fusion_missing_cover", label: "Remplir les vides + remplacer Cover"},
        {value: "fusion_missing_label", label: "Remplir les vides + remplacer Label"},
        {value: "fusion_missing_desc", label: "Remplir les vides + remplacer description"},
        {value: "fusion_missing_cover_desc", label: "Remplir les vides + remplacer Cover ET description"},
        {value: "unmatched", label: "Dematch du produit (desactive l'offre associée et supprime son shop sku"},
    ]

    return(
        <Select options={optionsMatch} onChange={props.handleChangeStatus} defaultValue={{label: "Remplir les vides", value: "fusion_missing"}}/>
    )
}

function SelectStatusRefused(props) {
    const optionsMatch = [
        {value: "pass", label: "Passer pour le moment"},
        {value: "refused", label: "Refuser"},
    ]

    return(
        <Select options={optionsMatch} onChange={props.handleChangeStatus}/>
    )
}

function ModalCreateCuvee(props) {

    const categoriesCode = {
        'Vins' : { id : 1, name: "Wine"},
        'Spiritueux': { id: 2, name: "Spirit"},
        'Bieres': {id: 3, name: "Beer"},
    };

    let categories = [];
    props.config?.categories.forEach(category => {
        categories[category.mirakl_id] = {value: category.id, label: category.name};
    });

    const [productData, setProductData] = useState({
        name : props.multiProduct?.name ? props.multiProduct.name : props.productValidation?.name,
        appellation: props.multiProduct?.multi_product_wine ? props.multiProduct.multi_product_wine.appellation.id : props.multiProduct?.appellation ? props.multiProduct.appellation.id : props.productValidation.appellation?.id,
        producer : props.multiProduct?.producer ? props.multiProduct.producer.id : props.productValidation.producer?.id,
        color: props.multiProduct?.multi_product_wine ? props.multiProduct.multi_product_wine.color.id : props.multiProduct?.color ? props.multiProduct.color.id : props.productValidation.color?.id,
        capacity : props.productValidation.capacity?.id,
        type: props.multiProduct?.product_type ? props.multiProduct.product_type.id : categoriesCode?.[props.productValidation.category_code] ? categoriesCode[props.productValidation.category_code].id : 4,
        typeName: props.multiProduct?.product_type ? capitalizeFirstLetter(props.multiProduct.product_type.name) : categoriesCode?.[props.productValidation.category_code] ? categoriesCode[props.productValidation.category_code].name : "Generic",
        vintage: props.productValidation.vintage?.id,
        spiritType: props.multiProduct?.multi_product_spirit ? props.multiProduct.multi_product_spirit.spirit_type.id : props.multiProduct?.spiritType ? props.multiProduct.spiritType.id : props.productValidation.spirit_type ? props.productValidation.spirit_type.id : undefined,
        spiritSubType: props.multiProduct?.multi_product_spirit ? props.multiProduct.multi_product_spirit.spirit_sub_type.id : props.multiProduct?.spiritSubType ? props.multiProduct.spiritSubType.id : props.productValidation.spirit_subtype ? props.productValidation.spirit_subtype.id : undefined,
        beerType: props.multiProduct?.multi_product_beer ? props.multiProduct.multi_product_beer.beer_type.id : props.multiProduct?.beerType ? props.multiProduct.beerType.id : props.productValidation.beer_type ? props.productValidation.beer_type.id : undefined,
        beerStyle: props.multiProduct?.multi_product_beer ? props.multiProduct.multi_product_beer.beer_style.id : props.multiProduct?.beerStyle ? props.multiProduct.beerStyle.id : props.productValidation.beer_style ? props.productValidation.beer_style.id : undefined,
        categories: categories?.[props.productValidation.category_code] ? categories[props.productValidation.category_code] : props.productValidation.category_code
    });
    const [badges, setBadges] = useState({
        name : "",
        appellation: "",
        producer: "",
        color: "",
        vintage: "",
        type: "",
        capacity: "",
        spiritType: "",
        spiritSubType: "",
        beerType: "",
        beerStyle: "",
        categories: "",
    });

    const [loading, setLoading] = useState("");
    const [loadingC, setLoadingC] = useState(<Loader/>);
    const [isDisabled, setIsDisabled] = useState(true);
    const [error, setError] = useState("");
    const [list, setlist] = useState([]);
    const [cuvees, setCuvees] = useState([]);
    const [retryFetch, setRetryFetch] = useState(false);

    const handleIsDisabled = useCallback(
        () => {
            switch (productData.type) {
                case 1:
                    if(productData.name !== undefined && productData.appellation !== undefined && productData.producer !== undefined && productData.color !== undefined && productData.vintage !== undefined && productData.capacity !== undefined) {
                        setIsDisabled(false)
                    } else {
                        setIsDisabled(true)
                    }
                    break;
                case 2:
                    if(productData.name !== undefined && productData.spiritType !== undefined && productData.producer !== undefined && productData.spiritSubType !== undefined && productData.vintage !== undefined && productData.capacity !== undefined) {
                        setIsDisabled(false)
                    } else {
                        setIsDisabled(true)
                    }
                    break;
                case 3:
                    if(productData.name !== undefined && productData.beerType !== "" && productData.producer !== undefined && productData.beerStyle !== "" && productData.capacity !== undefined) {
                        setIsDisabled(false)
                    } else {
                        setIsDisabled(true)
                    }
                    break;
                case 4:
                    if(productData.categories !== "") {
                        setIsDisabled(false)
                    } else {
                        setIsDisabled(true)
                    }
                    break;
                default:
                    setIsDisabled(true)
                    break;
            }
        },
        [productData]
    );

    const handleSetCuvees = (check, product) => {
        if (check) {
            setCuvees((prevState) => {
                const updatedCuvees = [...prevState, product];
                console.log(updatedCuvees); // Cela affichera la valeur mise à jour de cuvees
                return updatedCuvees;
            });
        } else {
            setCuvees((prevState) => {
                const updatedCuvees = prevState.filter((item) => item.id !== product.id);
                console.log(updatedCuvees); // Cela affichera la valeur mise à jour de cuvees
                return updatedCuvees;
            });
        }
    };

    useEffect(() => {
        setBadges(prevBadges => {
            const updatedBadges = { ...prevBadges };
            for (const badge in updatedBadges) {
                let icon = productData[badge] ? (
                    <Icon.CheckCircleFill className="text-success" />
                ) : (
                    <Icon.XCircleFill className="text-danger" />
                );
                updatedBadges[badge] = icon;
            }
            return updatedBadges;
        });
        handleIsDisabled();

        if(props.productValidation.pro_cuvee) {
            if (list.length === 0) {
                const params = {
                    "cuvee" : props.productValidation.pro_cuvee,
                    "limit" : 120
                }

                const url = process.env.REACT_APP_SF_URL + "api/1/fr_fr/productvalidation?" + new URLSearchParams(params).toString();

                fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        "host" : "dev-twil.co",
                        'Authorization': 'Bearer ' + props.token,
                    }})
                .then(res => res.json())
                .then((res) => {
                        if(res?._embedded?.items) {
                            if(res._embedded.items.length > 1) {
                                setLoadingC("Voulez vous matcher ces produits aussi ?");
                                const tmp = [];
                                const ids = [];
                                res._embedded.items.forEach((product, index) => {
                                    if(props.productValidation.id !== product.id) {
                                        tmp.push(<ProductRow
                                            product={product}
                                            key={index}
                                            check={true}
                                            setCuvee={handleSetCuvees}
                                        />)
                                        ids.push({"id" : product.id, "vintage" : product.vintage.id, "capacity" : product.capacity.id});
                                    }
                                });
                                console.log(ids);
                                setlist(tmp);
                                setCuvees(ids);
                                setRetryFetch(false);
                            } else {
                                setLoadingC("");
                            }
                        } else {
                            //re-try to fetch
                            setRetryFetch(true);
                        }
                    },
                    (error) => {
                        console.log("error");
                        console.log(error);
                        setRetryFetch(true);
                    }
                )
            }
        } else {
            setLoadingC("");
        }

    }, [productData, handleIsDisabled, list, retryFetch, props]);


    const vintages = [];
    props.config?.vintages.forEach(vintage =>
        vintages.push({value: vintage.id, label: vintage.name})
    )
    const capacities = [];
    props.config?.capacities.forEach(capacity =>
        capacities.push({value: capacity.id, label: capacity.name})
    )
    const types = [];
    props.config?.types.forEach(type =>
        types.push({value: type.id, label: capitalizeFirstLetter(type.name)})
    )

    const handleChangeProductData = (e, label = null) => {
        if(label) {
            if(label === "type") {
                setProductData({...productData, typeName:e.label, [label]:e.value});
            } else {
                setProductData({...productData,[label]:e.value});
            }
        } else {
            setProductData({...productData,[e.target.id]:e.target.value});
        }
    }


    const handleOnSubmit = (e) => {

        setLoading(<Loader/>);
        setError("");

        const excludedWords = ["magnum", "jéroboam", "75cl", "150cl", "300cl", props.productValidation.vintage?.name];

        if (excludedWords.some(v => productData.name.toLowerCase().includes(v))) {
            setLoading("");
            setError("Vous ne devez saisir que le nom de la cuvée, sans le domaine, le millésime, et la contenance");
            return;
        }

        const params = {
            productValidation: props.productValidation.id,
            type: productData.typeName
        }

        if(props.multiProduct?.id) {
            params['multiProduct'] = props.multiProduct?.id;
            if(productData.type !== 3) {
                params['vintage'] = productData.vintage;
            }
            if(productData.type !== 4) {
                params['capacity'] = productData.capacity;
            }
        } else {


            switch (productData.type) {
                case 1:
                    params['capacity'] = productData.capacity;
                    params['vintage'] = productData.vintage;
                    params['cuvee'] = {
                        color: productData.color,
                        appellation: productData.appellation,
                    }
                    break;
                case 3:
                    params['capacity'] = productData.capacity;
                    params['cuvee'] = {
                        beerStyle: productData.beerStyle,
                        beerType: productData.beerType,
                    }
                    break;
                case 2:
                    params['capacity'] = productData.capacity;
                    params['vintage'] = productData.vintage;
                    params['cuvee'] = {
                        spiritType: productData.spiritType,
                        spiritSubType: productData.spiritSubType,
                    }
                    break;
                case 4:
                    params['cuvee'] = {
                        categories: [productData.categories],
                    }
                    break;
                default:
                    break;
            }
            params['cuvee']['multiProduct'] = {
                name : productData.name,
                producer: productData.producer
            }
        }

        async function performFetch(cuvee) {
            params['productValidation'] = cuvee.id;
            params['capacity'] = cuvee.capacity;
            params['vintage'] = cuvee.vintage;

            const response = await fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'host': 'dev-twil.co',
                    'Authorization': 'Bearer ' + props.token,
                },
                body: JSON.stringify(params),
            });

            const data = await response.json();

            if (data?.status === false) {
                document.getElementById("check-" + cuvee.id).innerHTML = data.errors ? data.errors : data.error ? data.error : ":'(";
            } else {
                document.getElementById("check-" + cuvee.id).innerHTML = "Matché";
            }
        }

        async function processCuvees(cuvees) {
            for (let i = 0; i < cuvees.length; i++) {
                await performFetch(cuvees[i]);
            }

            // Toutes les requêtes fetch sont terminées
            console.log("Toutes les requêtes fetch sont terminées.");

            // Continuez avec le code après la boucle ici
        }

        if (cuvees.length > 0) {

            processCuvees(cuvees)
            .then(() => {
                // Code à exécuter après que toutes les requêtes fetch soient terminées
                console.log("Toutes les requêtes fetch sont terminées.");

                // Ajoutez ici le code que vous souhaitez exécuter après que toutes les requêtes fetch soient terminées
                params['productValidation'] = props.productValidation.id;
                params['capacity'] = productData.capacity;
                params['vintage'] = productData.vintage;
                fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/product', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "host" : "dev-twil.co",
                        'Authorization': 'Bearer ' + props.token,
                    },
                    body: JSON.stringify(params)
                }).then(data => data.json())
                    .then(res => {
                        setLoading("");
                        console.log(res);
                        if(res?.status === false) {
                            setError(res.errors ? res.errors : res.error ? res.error : "");
                        } else {
                            props.handleChangeProduct(res);
                            props.handleChangeStatus("fusion_missing");
                            props.onHide();
                        }
                        console.log(res);
                    })
            })
            .catch((error) => {
                console.log(error);
                setError(error);
                // Gérez les erreurs de l'une des requêtes fetch ici
            });
        } else {
            fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "host" : "dev-twil.co",
                    'Authorization': 'Bearer ' + props.token,
                },
                body: JSON.stringify(params)
            }).then(data => data.json())
            .then(res => {
                setLoading("");
                console.log(res);
                if(res?.status === false) {
                    setError(res.errors ? res.errors : res.error ? res.error : "");
                } else {
                    props.handleChangeProduct(res);
                    props.handleChangeStatus("fusion_missing");
                    props.onHide();
                }
            })
        }
    }

    const searchProducer = props.multiProduct?.producer ? props.multiProduct.producer.name : props.productValidation.producer?.name ? props.productValidation.producer.name : props.productValidation.producer_txt
    const readOnly = !!props.multiProduct?.id;
    const title = props.multiProduct?.id ? "Match sur une Cuvee" : "Nouvelle Cuvee";

    let formType;
    let formCapacity;
    switch (productData.type) {
        case 1:
            formType = <WineForm
                readOnly={readOnly}
                token={props.token}
                config={props.config}
                multiProduct={props.multiProduct}
                productValidation={props.productValidation}
                badges={badges}
                vintages={vintages}
                handleChangeProductData={handleChangeProductData}
            />;
            formCapacity = <>
                <Form.Group  as={Row} className="mb-3" controlId="capacity">
                    <Form.Label column sm="3">Capacité {badges.capacity}</Form.Label>
                    <Col sm="9">
                        <Select options={capacities} onChange={e => handleChangeProductData(e,"capacity")} defaultValue={{ label: props.productValidation.capacity?.name, value: props.productValidation.capacity?.id }}/>
                    </Col>
                </Form.Group>
            </>;
            break;
        case 3:
            formType = <BeerForm
                readOnly={readOnly}
                token={props.token}
                config={props.config}
                multiProduct={props.multiProduct}
                productValidation={props.productValidation}
                badges={badges}
                handleChangeProductData={handleChangeProductData}
            />;
            formCapacity = <>
                <Form.Group  as={Row} className="mb-3" controlId="capacity">
                    <Form.Label column sm="3">Capacité {badges.capacity}</Form.Label>
                    <Col sm="9">
                        <Select options={capacities} onChange={e => handleChangeProductData(e,"capacity")} defaultValue={{ label: props.productValidation.capacity?.name, value: props.productValidation.capacity?.id }}/>
                    </Col>
                </Form.Group>
            </>;
            break;
        case 2:
            formType = <SpiritForm
                readOnly={readOnly}
                token={props.token}
                config={props.config}
                multiProduct={props.multiProduct}
                productValidation={props.productValidation}
                badges={badges}
                vintages={vintages}
                handleChangeProductData={handleChangeProductData}
            />;
            formCapacity = <>
                <Form.Group  as={Row} className="mb-3" controlId="capacity">
                    <Form.Label column sm="3">Capacité {badges.capacity}</Form.Label>
                    <Col sm="9">
                        <Select options={capacities} onChange={e => handleChangeProductData(e,"capacity")} defaultValue={{ label: props.productValidation.capacity?.name, value: props.productValidation.capacity?.id }}/>
                    </Col>
                </Form.Group>
            </>;
            break;
        case 4:
            formType = <GenericForm
                readOnly={readOnly}
                token={props.token}
                config={props.config}
                multiProduct={props.multiProduct}
                productValidation={props.productValidation}
                badges={badges}
                vintages={vintages}
                handleChangeProductData={handleChangeProductData}
            />;
            formCapacity = "";
            break;
        default:
            break;
    }

    return (


        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group  as={Row} className="mb-3" controlId="type">
                        <Form.Label column sm="3">Type de produit {badges.type}</Form.Label>
                        <Col sm="9">
                            <Select isDisabled={readOnly} options={types} onChange={e => handleChangeProductData(e,"type")} defaultValue={{ label: productData.typeName, value: productData.typeName }}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="name">
                        <Form.Label column sm="3">Nom {badges.name}</Form.Label>
                        <Col sm="9">
                            <Form.Control readOnly={readOnly} type="input" placeholder="Nom" value={productData.name} onChange={handleChangeProductData}/>
                        </Col>
                    </Form.Group>

                    <MatchProducer readOnly={readOnly} config={props.config} token={props.token} search={searchProducer} badges={badges} handleChangeProductData={handleChangeProductData}/>
                    {formCapacity}
                    {formType}
                </Form>
                <Col>
                    <div className="mb-2">
                        {loadingC}
                    </div>
                    {list.length > 0 &&
                        <table className="table align-middle">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Image</th>
                                <th scope="col">Info</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                        </table>
                    }
                </Col>

            </Modal.Body>
            <Modal.Footer>
                <Col>
                    {loading}
                    <div className={"text-danger"}>
                        {error}
                    </div>
                </Col>
                <Button onClick={handleOnSubmit} disabled={isDisabled}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ProductRow(props) {
    const [check, setCheck] = useState(props.check);

    const checkbox = (check, product) => (e) => {
        props.setCuvee(!check, {"id" : product.id, "vintage" : product.vintage.id, "capacity" : product.capacity.id});
        setCheck(!check);
    };


    let url;
    let multiproductId;
    if(displayStatus(props.product.status) === "Matché") {
        console.log(props.product);
        switch (props.product.category_code) {
            case "Vins":
                multiproductId = props.product.matched_multi_product.multi_product_wine ? props.product.matched_multi_product.multi_product_wine.id : "";
                url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductwine/" + multiproductId + "/edit?tl=fr_fr";
                break;
            case "Spiritueux":
                multiproductId = props.product.matched_multi_product.multi_product_spirit ? props.product.matched_multi_product.multi_product_spirit.id : "";
                url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductspirit/" + multiproductId + "/edit?tl=fr_fr";
                break;
            case "Bieres":
                multiproductId = props.product.matched_multi_product.multi_product_beer ? props.product.matched_multi_product.multi_product_beer.id : "";
                url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductbeer/" + multiproductId + "/edit?tl=fr_fr";
                break;
            default:
                multiproductId = props.product.matched_multi_product.multi_product_generic ? props.product.matched_multi_product.multi_product_generic.id : "";
                url = process.env.REACT_APP_SF_URL + "admin/twil/product/multiproductgeneric/" + multiproductId + "/edit?tl=fr_fr";
                break;
        }
    }

    return(

        <tr onClick={checkbox(check, props.product)}>
            <th scope="row">
                <Form.Check type="checkbox" name={"cuvees"} checked={check} onChange={(check) => check}/>
            </th>
            <td>
                <ProductImage src={props.product?.label_url}/>
            </td>
            <td>
                <ul>
                    <li>SKU : {props.product.product_identifier}</li>
                    <li>Nom : {props.product.name}</li>
                    <li>Producteur : {props.product.producer_txt}</li>
                    <li>Appellation : {props.product.appellation_txt}</li>
                    {props.product?.color ? <li>Couleur : {props.product.color.name}</li> : "" }
                    <li>Millésime : {props.product.vintage.name}</li>
                    <li>Capacité : {props.product.capacity.name}</li>
                </ul>
            </td>
            <td id={"check-" + props.product.id}>
                {displayStatus(props.product.status)}
                {displayStatus(props.product.status) === "Matché" && <div>sur cette cuvée <a target={"_blank"} rel={"noreferrer"} href={url}>{multiproductId}</a></div>}
            </td>
        </tr>
    )
}



function WineForm(props) {

    const colors = [];
    props.config?.colors.forEach(color =>
        colors.push({value: color.id, label: color.name})
    )

    const searchAppellation = props.multiProduct?.multi_product_wine ? props.multiProduct.multi_product_wine.appellation.name : props.multiProduct?.appellation ? props.multiProduct.appellation.name : props.productValidation.appellation?.name ? props.productValidation.appellation.name : props.productValidation.appellation_txt
    const color = props.multiProduct?.multi_product_wine ? props.multiProduct.multi_product_wine.color.name : props.multiProduct?.color ? props.multiProduct.color.name : props.productValidation.color?.name


    return (
        <>
            <MatchAppellation readOnly={props.readOnly} token={props.token} search={searchAppellation} badges={props.badges} handleChangeProductData={props.handleChangeProductData}/>
            <Form.Group  as={Row} className="mb-3" controlId="color">
                <Form.Label column sm="3">Couleur {props.badges.color}</Form.Label>
                <Col sm="9">
                    <Select isDisabled={props.readOnly} options={colors} onChange={e => props.handleChangeProductData(e, "color")} defaultValue={{ label: color, value: color }}/>
                </Col>
            </Form.Group>
            <Form.Group  as={Row} className="mb-3" controlId="vintage">
                <Form.Label column sm="3">Millésime {props.badges.vintage}</Form.Label>
                <Col sm="9">
                    <Select options={props.vintages} onChange={e => props.handleChangeProductData(e, "vintage")} defaultValue={{ label: props.productValidation.vintage?.name, value: props.productValidation.vintage?.id }}/>
                </Col>
            </Form.Group>
        </>
    );
}

function BeerForm(props) {

    const beerTypes = [];
    props.config?.beerTypes.forEach(beerType =>
        beerTypes.push({value: beerType.id, label: beerType.name})
    )

    const beerStyles = [];
    props.config?.beerStyles.forEach(beerStyle =>
        beerStyles.push({value: beerStyle.id, label: beerStyle.name})
    )
    let typeLabel = props.multiProduct?.multi_product_beer ? props.multiProduct.multi_product_beer.beer_type.name : props.multiProduct?.beerType ? props.multiProduct.beerType.name : props.productValidation.beer_type ? props.productValidation.beer_type.name : "";
    let styleLabel = props.multiProduct?.multi_product_beer ? props.multiProduct.multi_product_beer.beer_style.name : props.multiProduct?.beerStyle? props.multiProduct.beerStyle.name : props.productValidation.beer_style ? props.productValidation.beer_style.name : "";

    return (
        <>
            <Form.Group  as={Row} className="mb-3" controlId="beerType">
                <Form.Label column sm="3">Type {props.badges.beerType}</Form.Label>
                <Col sm="9">
                    <Select isDisabled={props.readOnly} options={beerTypes} onChange={e => props.handleChangeProductData(e, "beerType")} defaultValue={{ label: typeLabel, value: typeLabel }} />
                </Col>
            </Form.Group>
            <Form.Group  as={Row} className="mb-3" controlId="beerStyle">
                <Form.Label column sm="3">Style {props.badges.beerStyle}</Form.Label>
                <Col sm="9">
                    <Select isDisabled={props.readOnly} options={beerStyles} onChange={e => props.handleChangeProductData(e, "beerStyle")} defaultValue={{ label: styleLabel, value: styleLabel }}/>
                </Col>
            </Form.Group>
        </>
    );
}

function SpiritForm(props) {

    const spiritTypes = [];
    let spiritSubTypes = [];
    const [selectValue, setSelectValue] = useState([]);


    props.config?.spiritTypes.forEach(spiritType =>
        spiritTypes.push({value: spiritType.id, label: spiritType.name})
    )

    useEffect(() => {
            setSelectValue(() => {
                props.config?.spiritSubTypes.forEach(spiritSubType => {
                    if(spiritSubType.spirit_type.id === props.productValidation?.spirit_type?.id) {
                        spiritSubTypes.push({value: spiritSubType.id, label: spiritSubType.name})
                    }
                })
                return spiritSubTypes;
            });
    }, [setSelectValue]);


    const handleChangeSpirit = (e) => {
        spiritSubTypes = [];
        props.config?.spiritSubTypes.forEach(spiritSubType => {
            if(spiritSubType.spirit_type.id === e.value) {
                spiritSubTypes.push({value: spiritSubType.id, label: spiritSubType.name})
            }
        })
        setSelectValue(spiritSubTypes);
        props.handleChangeProductData(e, "spiritType");
    }

    let spiritLabel = props.multiProduct?.multi_product_spirit ? props.multiProduct.multi_product_spirit.spirit_type.name : props.multiProduct?.spiritType ? props.multiProduct.spiritType.name : props.productValidation.spirit_type ? props.productValidation.spirit_type.name : "";
    let spiritSubLabel = props.multiProduct?.multi_product_spirit ? props.multiProduct.multi_product_spirit.spirit_sub_type.name : props.multiProduct?.spiritSubType ? props.multiProduct.spiritSubType.name : props.productValidation.spirit_subtype ? props.productValidation.spirit_subtype.name : "";

    return (
        <>
            <Form.Group  as={Row} className="mb-3" controlId="spiritType">
                <Form.Label column sm="3">Type {props.badges.spiritType}</Form.Label>
                <Col sm="9">
                    <Select isDisabled={props.readOnly} options={spiritTypes} onChange={e => handleChangeSpirit(e)} defaultValue={{ label: spiritLabel, value: spiritLabel }}/>
                </Col>
            </Form.Group>
            <Form.Group  as={Row} className="mb-3" controlId="spiritSubType">
                <Form.Label column sm="3">Sous-type {props.badges.spiritSubType}</Form.Label>
                <Col sm="9">
                    <Select isDisabled={props.readOnly} options={selectValue} onChange={e => props.handleChangeProductData(e, "spiritSubType")} defaultValue={{ label: spiritSubLabel, value: spiritSubLabel }}/>
                </Col>
            </Form.Group>
            <Form.Group  as={Row} className="mb-3" controlId="vintage">
                <Form.Label column sm="3">Millésime {props.badges.vintage}</Form.Label>
                <Col sm="9">
                    <Select options={props.vintages} onChange={e => props.handleChangeProductData(e, "vintage")} defaultValue={{ label: props.productValidation.vintage?.name, value: props.productValidation.vintage?.id }}/>
                </Col>
            </Form.Group>
        </>
    );
}

function GenericForm(props) {


    const categories = [];
    let categoryDefault = { "name" : props.productValidation.category_code, "value" : props.productValidation.category_code};
    props.config?.categories.forEach(category => {
        categories.push({value: category.id, label: category.name})
        if (category.mirakl_id === props.productValidation.category_code) {
            categoryDefault = category;
        }
    });

    return (
        <>
            <Form.Group  as={Row} className="mb-3" controlId="categories">
                <Form.Label column sm="3">Categorie {props.badges.categories}</Form.Label>
                <Col sm="9">
                    <Select isDisabled={props.readOnly} options={categories} onChange={e => props.handleChangeProductData(e, "categories")} defaultValue={{ label: categoryDefault.name, value: categoryDefault.id }}/>
                </Col>
            </Form.Group>
        </>
    );
}

function ModalCreateProducer(props) {

    const [producer,setProducer] = useState(props.producer);
    const [region, setRegion] = useState("");
    const [typesSold, setTypesSold] = useState([]);
    const [regionType, setRegionType] = useState(<></>);

    const regions = [];
    props.regions?.forEach(region => {
       regions.push({value: region.id, label:region.name})
    });

    const types = [];
    props.types?.forEach(type => {
        types.push({value: type.id, label:type.name})
    });
    const handleRegion = (e) => {
        setRegion(e.value);
    }

    const handleTypes = (e) => {
        const checked = document.querySelectorAll('input[name="types"]:checked');
        var selected = Array.from(checked).map(x => x.value * 1);
        setTypesSold(selected);
        if(e.value === "1") {
            if(e.checked) {
               setRegionType(
               <>
                   <Form.Group as={Row} className="mb-3" controlId="regions">
                       <Form.Label column sm="3">Regions </Form.Label>
                       <Col sm="9">
                           <Select options={regions} onChange={handleRegion}/>
                       </Col>
                   </Form.Group>
               </>);
            } else {
                setRegionType(<></>);
            }
        }
    }

    const saveProducer = () => {
        const params = {
            name : producer,
            vineyardRegion : region,
            productTypesSold : typesSold
        }

        console.log(params);

        fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/producer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "host" : "dev-twil.co",
                'Authorization': 'Bearer ' + props.token,
            },
            body: JSON.stringify(params)
        }).then(data => data.json())
        .then(res => {
            console.log(res);
            props.onSave(res);
        })
        props.onHide();
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nouveau Domaine
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className={'mb-3'}>
                        <Form.Label column lg={3}>
                            Type de produits vendus
                        </Form.Label>
                        <Col key={`inline-checkbox`} sm="9">
                            {types.map((type) => (
                                    <Form.Check
                                        key={type.value}
                                        inline
                                        label={type.label}
                                        name="types"
                                        type="checkbox"
                                        id={type.value}
                                        value={type.value}
                                        onChange={event => handleTypes(event.target)}
                                    />
                            ))}
                        </Col>
                    </Row>
                    <Row className={'mb-3'}>
                        <Form.Label column lg={3}>
                            Domaine
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder="Choisissez un domaine" value={producer} onChange={event => setProducer(event.target.value)}/>
                        </Col>
                    </Row>
                    {regionType}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={saveProducer}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ButtonModalCuvee(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant="primary" className={"mt-3"}  onClick={() => setModalShow(true)}>Enregistrer</Button>
            { modalShow &&
            <ModalCreateCuvee
                show={modalShow}
                onHide={() => setModalShow(false)}
                config={props.config}
                token={props.token}
                productValidation={props.productValidation}
                multiProduct={props.multiProduct}
                handleChangeProduct={props.handleChangeProduct}
                handleChangeStatus={props.handleChangeStatus}
            />
            }
        </>
    );
}

function ButtonModalProducer(props) {
    const [modalShow, setModalShow] = React.useState(false);

    const saveProducer = (producer) => {
        console.log(producer);
        props.setProducer({value : producer.id});
        props.handleInputChange(producer.name);
    }

    return (
        <>
            <Form.Text className="text-primary" role="button"  onClick={() => setModalShow(true)}>
                <u>Créer un nouveau domaine</u>
            </Form.Text>

            <ModalCreateProducer
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSave={saveProducer}
                regions={props.config?.regions}
                types={props.config?.types}
                producer={props.producer}
                token={props.token}
            />
        </>
    );
}

class MatchProducer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "search" : this.props.search
        }
    }

    getTypesOptions =  () => {
        if (this.state.search) {
            const params = {
                "type" : "producer",
                "search" : this.state.search
            }
            return fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "host" : "dev-twil.co",
                    'Authorization': 'Bearer ' + this.props.token,
                },
                body: JSON.stringify(params)
            }).then(data => data.json())
            .then(res => {
                const result = [];
                res.forEach(producer => {
                    result.push({value : producer.id, label : producer.name})
                });
                return result;
            })
        } else {
            return [];
        }

    }

    handleInputChange = (e) => {
        this.setState({
            "search" : e
        })
    }

    handleChange = (e) => {
        this.props.handleChangeProductData(e, "producer");
    }

    render() {
        let select;
        if(this.props.readOnly) {
            select = (
                <>
                    <Select isDisabled={true} defaultValue={{ label: this.props.search, value: 1 }}/>
                </>
            )
        } else {
            select = (
                <>
                    <AsyncSelect
                        defaultInputValue={this.state.search}
                        onInputChange={this.handleInputChange}
                        loadOptions={this.getTypesOptions}
                        defaultOptions={true}
                        onChange={this.handleChange}
                    />
                    <ButtonModalProducer config={this.props.config} producer={this.props.search} setProducer={this.handleChange} handleInputChange={this.handleInputChange} token={this.props.token}/>
                </>
            )
        }
        return (
            <Row className={'mb-3'}>
                <Form.Label column lg={3}>
                    Domaine {this.props.badges.producer}
                </Form.Label>
                <Col>
                    {select}
                </Col>
            </Row>
        );
    }
}

class MatchAppellation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "search" : this.props.search
        }
    }

    getTypesOptions =  () => {
        if (this.state.search) {
            const params = {
                "type" : "appellation",
                "search" : this.state.search
            }
            return fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "host" : "dev-twil.co",
                    'Authorization': 'Bearer ' + this.props.token,
                },
                body: JSON.stringify(params)
            }).then(data => data.json())
                .then(res => {
                    const result = [];
                    res.forEach(appellation => {
                        result.push({value : appellation.id, label : appellation.name})
                    });
                    return result;
                })
        } else {
            return [];
        }

    }

    handleInputChange = (e) => {
        this.setState({
            "search" : e
        })
    }

    handleChange = (e) => {
        this.props.handleChangeProductData(e, "appellation")
    }

    render() {
        let select;
        if(this.props.readOnly) {
            select = (
                <>
                    <Select isDisabled={true} defaultValue={{ label: this.props.search, value: 1 }}/>
                </>
            )
        } else {
            select = (
                <>
                    <AsyncSelect
                        defaultInputValue={this.state.search}
                        onInputChange={this.handleInputChange}
                        onChange={this.handleChange}
                        loadOptions={this.getTypesOptions}
                        defaultOptions={true}
                    />
                </>
            )
        }
        return (
            <Row className={'mb-3'}>
                <Form.Label column lg={3}>
                    Appellation {this.props.badges.appellation}
                </Form.Label>
                <Col>
                    {select}
                </Col>
            </Row>
        );
    }
}

class ResultSearchCuveeRow extends React.Component {



    handleChange = () => {
        this.props.handlerMultiproduct(this.props.row);
        this.props.handleSelected();
    }

    render() {
        // console.log(this.props.row);
        return (
            <>
                <Form.Check
                    type="radio"
                    label={this.props.row.label}
                    name={"ResultSearchCuvee-" + this.props.id}
                    id={this.props.row.id + "-" + this.props.id}
                    value={this.props.row.id}
                    onChange={this.handleChange}
                />
            </>
        );
    }
}

class ResultSearchCuvee extends React.Component {

    render() {
        const results = [];
        this.props.results.forEach((row, move) => {
            results.push(<ResultSearchCuveeRow
                    row={row}
                    key={row.id}
                    handlerMultiproduct={this.props.handlerMultiproduct}
                    handleSelected={this.props.handleSelected}
                    id={this.props.id}
                />
            );
        });
        return (
            <Form>
                <Form.Group as={Row}>
                    <Col sm={12} className={"mt-3"}>
                        {results}
                    </Col>
                </Form.Group>
            </Form>
        );
    }
}

class SearchBarCuvee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'results' : [],
            'search': this.props.name,
            'loading': false,
            'strict': true
        }
    }

    searchProduct(product, strict) {
        this.setState({
            loading: true
        });
        const params = {
            "search" : product
        }

        if (strict) {
            if(this.props.productTypeId) {
                params["product_type"] = this.props.productTypeId;
            }
            if (this.props.productValidation?.color) {
                params['color'] = this.props.productValidation?.color.id;
            }
            if (this.props.productValidation?.spiritType) {
                params['spirit_type'] = this.props.productValidation?.spiritType.id;
            }
            if (this.props.productValidation?.spiritSubtype) {
                params['spirit_sub_type'] = this.props.productValidation?.spiritSubtype.id;
            }
            if (this.props.productValidation?.beerType) {
                params['beer_type'] = this.props.productValidation?.beerType.id;
            }
            if (this.props.productValidation?.beerStyle) {
                params['beer_style'] = this.props.productValidation?.beerStyle.id;
            }
            if (this.props.productValidation?.producer) {
                params['producer'] = this.props.productValidation?.producer.id;
            }
        }

        fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "host" : "dev-twil.co",
                'Authorization': 'Bearer ' + this.props.token,
            },
            body: JSON.stringify(params)
        }).then(data => data.json())
            .then(
                (result) => {
                    if(this.mounted) {
                        if(Array.isArray(result)) {
                            this.setState({
                                results: result,
                                loading: false
                            });
                        } else {
                            this.searchProduct(product, strict);
                        }
                    }
                },
                // Remarque : il est important de traiter les erreurs ici
                // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
                // des exceptions provenant de réels bugs du composant.
                (error) => {
                    this.setState({
                        results: [],
                        loading: false
                    });
                    console.log("error");
                    console.log(error);
                }
            )
    }

    componentDidMount() {
        this.mounted = true;
        if(!this.props.multiProduct?.id) {
            this.searchProduct(this.props.name, this.state.strict);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange = (e) => {
        try {
            this.setState({
                "search" : e.target.value
            });
            if(e.target.value.length > 2 && !this.props.multiProduct?.id) {
                this.searchProduct(e.target.value, this.state.strict);
            }
        } catch (err) {
            console.log(err);
            if (err.error !== 'login_required') console.log(err.error);
        }
    }

    handleCheckbox = (e) => {
        try {
            this.setState({
                "strict" : e.target.checked
            });
            this.searchProduct(this.state.search, e.target.checked);
        } catch (err) {
            console.log(err);
            if (err.error !== 'login_required') console.log(err.error);
        }
    }

    handleCloseSearch = (e) => {
        this.setState({
            "results": []
        });
        this.props.handlerMultiproduct({});
        this.searchProduct(this.state.search, this.state.strict);
    }

    handleSelected = (e) => {
        this.setState({
            "results": []
        });
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Désactiver la recherche strict sur ce match
        </Tooltip>
    );


    render() {
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return (
            <>
                <Row>
                    <Col sm={2}>
                        <Form.Label>
                            Recherche Cuvée :
                        </Form.Label>
                    </Col>
                    <Col sm={10}>
                        <InputGroup>
                            <InputGroup.Text>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip}
                                >
                                <Form.Check type="checkbox" checked={this.state.strict}  onChange={this.handleCheckbox}/>
                                </OverlayTrigger>
                            </InputGroup.Text>
                            <Form.Control type="text" value={this.state.search} placeholder="Nom de Cuvée" onChange={this.handleChange}/>
                            <InputGroup.Text>
                                    <Icon.XLg onClick={this.handleCloseSearch}/>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <span style={loadingTextCSS} className={"text-center mt-3"}>
                    <Spinner animation="grow" variant="primary" />
                </span>
                <ResultSearchCuvee id={this.props.id} results={this.state.results} handlerMultiproduct={this.props.handlerMultiproduct} handleSelected={this.handleSelected}/>


            </>
        );
    }
}

class Pending extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status : "pass",
            loading: "",
            error : "",
            disabled: true
        }
    }

    handleChangeStatus = (e) => {
        this.setState({
            status: e.value,
            disabled: false
        })
    }

    handleClick = (e) => {
        this.setState({
            loading: <Loader/>,
            error: ""
        })
        const params = {
            match_status: this.state.status,
            productValidation: this.props.productValidation.id
        }

        fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/changeStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "host" : "dev-twil.co",
                'Authorization': 'Bearer ' + this.props.token,
            },
            body: JSON.stringify(params)
        }).then(data => data.json())
            .then((res) => {
                this.setState({
                    loading: ""
                })
                if(res?.status === false) {
                    this.setState({
                        error: res.errors
                    })
                } else {
                    this.props.handleChangeStatus(this.state.status);
                }
            },(error) => {
                console.log("error");
                console.log(error);
            })
    }

    render() {
        let explication;
        let description;
        let name;
        let productTypeId;
        switch (this.props.productValidation.category_code) {
            case "Vins":
                name = this.props.productValidation?.name + " " + (this.props.productValidation?.producer_txt ? this.props.productValidation.producer_txt : "")  + " " + (this.props.productValidation?.appellation_txt ? this.props.productValidation.appellation_txt : "");
                productTypeId = 1;
                break;
            case "Spiritueux":
                name = this.props.productValidation?.name + " " + (this.props.productValidation.spirit_type?.name ? this.props.productValidation.spirit_type.name : "") + " " + (this.props.productValidation.spirit_subtype?.name ? this.props.productValidation.spirit_subtype.name : "") + " " + (this.props.productValidation?.producer_txt ? this.props.productValidation.producer_txt : "");
                productTypeId = 2;
                break;
            case "Bieres":
                name = this.props.productValidation?.name + " " + (this.props.productValidation.beer_type?.name ? this.props.productValidation.beer_type.name : "") + " " + (this.props.productValidation.beer_style?.name ? this.props.productValidation.beer_style.name : "") + " " + (this.props.productValidation?.producer_txt ? this.props.productValidation.producer_txt : "");
                productTypeId = 3;
                break;
            default:
                name = this.props.productValidation?.name + " " + (this.props.productValidation?.producer_txt ? this.props.productValidation.producer_txt : "");
                productTypeId = 4;
                break;
        }

        if(this.props.multiproduct?.id) {
            explication = "Match sur la cuvée et création d'un produit";
            const imgLabel = typeof this.props.multiproduct.label === 'object' ? this.props.multiproduct.label.screen_url : "";
            const imgCover = typeof this.props.multiproduct.cover === 'object' ? this.props.multiproduct.cover.screen_url : "";
                description = (
                <Row>
                    <Col xs={4}>
                        <div className={"imgs"}>
                            <ProductImage src={imgLabel}/>
                            <ProductImage src={imgCover}/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <ProductDescription description={this.props.description}/>
                    </Col>
                </Row>
            );
        } else {
            explication = "Créer la cuvée et le produit à partir des informations vendeur";
            description = "";
        }

        return(
            <Col>
                <SearchBarCuvee
                    multiProduct={this.props.multiproduct}
                    id={this.props.productValidation.id}
                    handlerMultiproduct={this.props.handlerMultiproduct}
                    token={this.props.token}
                    name={name}
                    productTypeId={productTypeId}
                    productValidation={this.props.productValidation}
                />
                {description}
                <hr/>
                <div className={"text-center mt-3 mb-3"}>
                    <div className={"fw-bold"}>{explication}</div>
                    <ButtonModalCuvee handleChangeStatus={this.props.handleChangeStatus} handleChangeProduct={this.props.handleChangeProduct} multiProduct={this.props.multiproduct} config={this.props.config} token={this.props.token} productValidation={this.props.productValidation}/>
                </div>
                <div className={"text-center mt-4 mb-3"}>
                    <hr className={"hr-or"}/>
                    <Form>
                        <Form.Group  as={Row} className="mb-3" controlId="status">
                            <Col sm="9">
                                <SelectStatusRefused  handleChangeStatus={this.handleChangeStatus}/>
                            </Col>
                            <Col sm="3">
                                <Button onClick={this.handleClick} disabled={this.state.disabled}>Valider le choix</Button>
                            </Col>
                            <Col sm="12" className={"mt-2"}>
                                <div className={"text-center"}>
                                    {this.state.loading}
                                </div>
                                <div className={"text-danger"}>
                                    {this.state.error}
                                </div>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            </Col>
            )
    }
}

class Match extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status : "matched",
            loading: "",
            error : ""
        }
    }

    handleChangeStatus = (e) => {
        this.setState({
            status: e.value
        })
    }

    handleClick = (e) => {
        this.setState({
            loading: <Loader/>,
            error: ""
        })
        const params = {
            match_status: this.state.status,
            productValidation: this.props.productValidation.id
        }


        fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/changeStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "host" : "dev-twil.co",
                'Authorization': 'Bearer ' + this.props.token,
            },
            body: JSON.stringify(params)
        }).then(data => data.json())
        .then((res) => {
            this.setState({
                loading: ""
            })
            if(res?.status === false) {
                this.setState({
                    error: res.errors
                })
            } else if(res?.status === true) {
                this.props.handleChangeStatus(this.state.status);
            } else {
                this.props.handleChangeProduct(res);
                this.props.handleChangeStatus(this.state.status);
            }
            console.log(res);
            //setLoading("");
        },(error) => {
            console.log("error");
            console.log(error);
        })
    }

    render() {
        const { product } = this.props;
        const historyMatch = [];
        if(this.props.productValidation.product_validation_status_history?.[0]) {
            this.props.productValidation.product_validation_status_history.forEach((history) => {
                historyMatch.push(<li key={history.id}> {"Context : " + history.context + " / " + history?.user?.lastname + " " + history?.user?.firstname + " / " + history.status + " / " + history.created_at}</li>)
            });
        } else {
            historyMatch.push("Aucune info 🥲")
        }


        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Historique de Match : </Popover.Header>
                <Popover.Body>
                    <ul>
                        {historyMatch}
                    </ul>
                </Popover.Body>
            </Popover>
        );
        const imgs = (product?.id) ? (<><ProductImage src={product?.label.screen_url}/><ProductImage src={product?.cover.screen_url}/></>) : "C'est une erreur ici, il n'y a pas de produit match";

        return(
            <>
                <Col xs={4}>
                    <div className={"imgs"}>
                        {imgs}
                    </div>
                </Col>
                <Col xs={8}>
                    <ProductDescription description={this.props.description}/>
                </Col>
                <Col>
                    <hr/>
                    <Form>
                        <Form.Group  as={Row} className="mb-3" controlId="status">
                            <Col sm="1">
                                <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                    <Icon.InfoCircleFill className={"text-warning mt-1"}/>
                                </OverlayTrigger>
                            </Col>
                            <Col sm="8">
                                <SelectStatus handleChangeStatus={this.handleChangeStatus}/>
                            </Col>
                            <Col sm="3">
                                <Button onClick={this.handleClick} >Valider le choix</Button>
                            </Col>
                            <Col sm="12" className={"mt-2"}>
                                <div className={"text-center"}>
                                    {this.state.loading}
                                </div>
                                <div className={"text-danger"}>
                                    {this.state.error}
                                </div>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </>
        )
    }
}

class Dematch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: "",
            error : ""
        }
    }

    handleChangeStatus = (e) => {
        if(e === "matched") {

            this.setState({
                loading: <Loader/>,
                error: ""
            })

            const params = {
                match_status: "fusion_missing",
                productValidation: this.props.productValidation.id
            }

            fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/changeStatus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "host" : "dev-twil.co",
                    'Authorization': 'Bearer ' + this.props.token,
                },
                body: JSON.stringify(params)
            }).then(data => data.json())
            .then((res) => {
                this.setState({
                    loading: ""
                })
                if(res?.status === false) {
                    this.setState({
                        error: res.errors
                    })
                } else if(res?.status === true) {
                    this.props.handleChangeStatus(e);
                } else {
                    this.props.handleChangeStatus(e);
                }
                console.log(res);
                //setLoading("");
            },(error) => {
                console.log("error");
                console.log(error);
            })
        } else {
            this.props.handleChangeStatus(e);
            this.props.handlerMultiproduct({});
            this.props.handleChangeProduct({});
        }
    }

    handleMatchAuto = () => {

        this.setState({
            loading: <Loader/>,
            error: ""
        })

        const params = {
            productValidation: this.props.productValidation.id
        }

        fetch(process.env.REACT_APP_SF_URL + 'api/1/fr_fr/productvalidation/automatch/multiproduct', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "host" : "dev-twil.co",
                'Authorization': 'Bearer ' + this.props.token,
            },
            body: JSON.stringify(params)
        }).then(data => data.json())
            .then((res) => {
                this.setState({
                    loading: ""
                })
                if(res?.status === false) {
                    this.setState({
                        error: res.errors
                    })
                } else if(res.status === this.props.status) {
                    this.setState({
                        error: "Le match a échoué, veuillez saisir manuellement merci 🤙"
                    })
                } else {
                    this.props.handleChangeProductValidation(res);
                }
                console.log(res);
                //setLoading("");
            },(error) => {
                console.log("error");
                console.log(error);
            })
    }

    render() {
        const { product } = this.props;
        let imgs = "";
        let disabled = true;
        const historyMatch = [];
        if(this.props.productValidation.product_validation_status_history?.[0]) {
            this.props.productValidation.product_validation_status_history.forEach((history) => {
                historyMatch.push(<li key={history.id}> {"Context : " + history.context + " / " + history?.user?.lastname + " " + history?.user?.firstname + " / " + history.status + " / " + history.created_at}</li>)
            });
        } else {
            historyMatch.push("Aucune info :'(")
        }
        if(product?.id) {
            disabled = false;
            imgs = (
                <>
                    <ProductImage src={product?.cover.screen_url}/>
                    <ProductImage src={product?.label.screen_url}/>
                </>
            )
        }


        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Historique de Match : </Popover.Header>
                <Popover.Body>
                    {historyMatch}
                </Popover.Body>
            </Popover>
        );

        return(
            <>
                <Col xs={4}>
                    <div className={"imgs"}>
                        {imgs}
                    </div>
                </Col>
                <Col xs={8}>
                    <ProductDescription description={this.props.description}/>
                </Col>
                <Col>
                    <hr/>
                    <Form>
                        <Form.Group  as={Row} className="mb-3" controlId="status">
                            <div className={"d-flex justify-content-around"}>
                                <div className={"mt-2"}>
                                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                        <Icon.InfoCircleFill className={"text-warning mt-1"}/>
                                    </OverlayTrigger>
                                </div>
                                <div className={"mt-2"}>
                                    <Button onClick={this.handleMatchAuto}>Relancer le match Auto</Button>
                                </div>
                                <div className={"mt-2"}>
                                    <Button onClick={() => this.handleChangeStatus("matched")} disabled={disabled}>Lier au produit</Button>
                                </div>
                                <div className={"mt-2"}>
                                    <Button onClick={() => this.handleChangeStatus("pending")}>Saisir manuellement</Button>
                                </div>
                            </div>
                            <Col className={"mt-3 text-center"}>
                                {this.state.loading}
                                {this.state.error}
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </>
        )
    }
}

class CuveeTwil extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "multiproduct": this.props.productValidation.matched_multi_product?.id ? this.props.productValidation.matched_multi_product : {}
        }
    }

    handlerMultiproduct = (multi) => {
        console.log(multi);
        this.setState({
            "multiproduct": multi
        })
    }

    render() {
        const { product } = this.props;
        const { multiproduct } = this.state;
        const { productValidation } = this.props;
        let productType = multiproduct.product_type ? multiproduct.product_type.id : "";
        let multiproductId;
        const otherInfo = JSON.parse(productValidation.other_info);
        switch (productType) {
            case 1:
                multiproductId = multiproduct?.multi_product_wine ? multiproduct.multi_product_wine.id : multiproduct?.typed_id ? multiproduct?.typed_id : multiproduct.id;
                break;
            case 2:
                multiproductId = multiproduct?.multi_product_spirit ? multiproduct.multi_product_spirit.id : multiproduct?.typed_id ? multiproduct?.typed_id : multiproduct.id ;
                break;
            case 3:
                multiproductId = multiproduct?.multi_product_beer ? multiproduct.multi_product_beer.id : multiproduct?.typed_id ? multiproduct?.typed_id : multiproduct.id ;
                break;
            case 4:
                multiproductId = multiproduct?.multi_product_generic ? multiproduct.multi_product_generic.id : multiproduct?.typed_id ? multiproduct?.typed_id : multiproduct.id ;
                break;
            default:
                multiproductId = "";
                break;
        }

        let desc = {
            ...(multiproduct?.name && { "Nom": {"value": multiproduct.name}}),
            ...(multiproduct?.producer && { "Domaine": {"value": multiproduct.producer.label}}),
            ...((multiproduct?.appellation || multiproduct?.multi_product_wine) && { "Appellation": {"value": multiproduct?.multi_product_wine ? multiproduct.multi_product_wine.appellation.label : multiproduct.appellation?.label}}),
            ...((multiproduct?.color || multiproduct?.multi_product_wine) && { "Couleur": {"value": multiproduct?.multi_product_wine ? multiproduct.multi_product_wine.color.name : multiproduct.color.name}}),
            ...((multiproduct?.beerType || multiproduct?.multi_product_beer) && { "Type de bière": {"value": multiproduct?.multi_product_beer?.beer_type ? multiproduct.multi_product_beer.beer_type.name : multiproduct?.beerType?.name}}),
            ...((multiproduct?.beerStyle || multiproduct?.multi_product_beer) && { "Style de bière": {"value": multiproduct?.multi_product_beer?.beer_style ? multiproduct.multi_product_beer.beer_style.name : multiproduct?.beerStyle?.name}}),
            ...((multiproduct?.spiritType || multiproduct?.multi_product_spirit) && { "Type de spirit": {"value": multiproduct?.multi_product_spirit?.spirit_type ? multiproduct.multi_product_spirit.spirit_type.name : multiproduct?.spiritType?.name}}),
            ...((multiproduct?.spiritSubType || multiproduct?.multi_product_spirit) && { "Sous-Type de spirit": {"value": multiproduct?.multi_product_spirit?.spirit_sub_type ? multiproduct.multi_product_spirit.spirit_sub_type.name : multiproduct?.spiritSubType?.name}}),
            ...((multiproduct?.categories || multiproduct?.multi_product_generic) && { "Categories": {"value": multiproduct?.multi_product_spirit?.spirit_sub_type ? multiproduct.multi_product_spirit.spirit_sub_type.name : multiproduct?.spiritSubType?.name}}),
        };

        if(multiproduct?.categories || multiproduct?.multi_product_generic) {
            var text = "";
            (multiproduct?.multi_product_generic ? multiproduct?.multi_product_generic.categories : multiproduct?.categories).map( category =>  {
                text = text.concat(category.name, ", ");
                return null;
            });
            desc["Categories"] = {"value" : text};
        }

        if(product?.id) {
             desc = {
                ...({ "Nom": {"value": product.name, "color": "text-success"}}),
                ...({ "Domaine": {"value": product.producer.name, "color": "text-success"}}),
                ...(product?.appellation && { "Appellation": {"value": product.appellation.name, "color": "text-success"}}),
                ...(product?.color && { "Couleur": {"value": product.color.name, "color": "text-success"}}),
                ...(product?.vintage && { "Millesime": {"value": product.vintage.name, "color": "text-success"}}),
                ...(product?.capacity && { "Capacite": {"value": product.capacity.name, "color": "text-success"}}),
                ...(productValidation?.serving_temperature && { "Temperature": {"value": product.card.serving_temperature, "color": "text-success"}}),
                ...(productValidation?.decanting && { "Carafage": {"value": product.card.decanting, "color": "text-success"}}),
                ...(productValidation?.garde && { "Garde": {"value": product.card.garde, "color": "text-success"}}),
                ...(productValidation?.apogee && { "Apogee": {"value": product.card.apogee, "color": "text-success"}}),
                ...(productValidation?.terroir && { "Terroir": {"value": product.card.terroir, "color": "text-success"}}),
                ...(productValidation?.type_culture && { "Type de culture": {"value": product.card.type_culture?.name, "color": "text-success"}}),
                ...(productValidation?.sulfites && { "Sulfites": {"value": product.card.sulfites, "color": "text-success"}}),
                ...(productValidation?.sugar_rate && { "Taux de sucre": {"value": product.card.sugar_rate, "color": "text-success"}}),
                ...(productValidation?.alcohol_rate && { "Taux d'alcool": {"value": product.card.alcohol, "color": "text-success"}}),
                ...((productValidation?.grapes1 && product?.card?.grapes) && { "Grapes": {"value": product.card.grapes.map(grape => grape.name).join(), "color": "text-success"}}),
                ...(productValidation?.description && { "Description": {"value": product.card.description, "color": "text-success"}}),
                ...(productValidation?.eye_description && { "Description Oeil": {"value": product.card.eye_description, "color": "text-success"}}),
                ...(productValidation?.nose_description && { "Description Nez": {"value": product.card.nose_description, "color": "text-success"}}),
                ...(productValidation?.mouth_description && { "Description Bouche": {"value": product.card.mouth_description, "color": "text-success"}}),
                ...((productValidation?.mouth_attribute1 && product?.card?.mouth_attributes) && { "Attribut de bouche": {"value": product.card.mouth_attributes.map(mouth => mouth.name).join(), "color": "text-success"}}),
                ...((productValidation?.nose_attribute1 && product?.card?.nose_attributes) && { "Attribut de nez": {"value": product.card.nose_attributes.map(nose => nose.name).join(), "color": "text-success"}}),
                ...(productValidation?.food_pairing_appetizer && { "Accords Mets/Vins Entree": {"value": product?.foodparing?.entree, "color": "text-success"}}),
                ...(productValidation?.food_pairing_meat && { "Accords Mets/Vins Viande": {"value": product?.foodparing?.meat, "color": "text-success"}}),
                ...(productValidation?.food_pairing_fish && { "Accords Mets/Vins Poisson": {"value": product?.foodparing?.fish, "color": "text-success"}}),
                ...(productValidation?.food_pairing_vegetable && { "Accords Mets/Vins Legumes": {"value": product?.foodparing?.vegetables, "color": "text-success"}}),
                ...(productValidation?.food_pairing_cheese && { "Accords Mets/Vins Fromage": {"value": product?.foodparing?.cheese, "color": "text-success"}}),
                ...(productValidation?.food_pairing_dessert && { "Accords Mets/Vins Dessert": {"value": product?.foodparing?.dessert, "color": "text-success"}}),
                 ...(productValidation?.beer_type && { "Type de biere": {"value" : product?.beer_type?.name, "color": "text-success" }}),
                 ...(productValidation?.beer_style && { "Style de biere": {"value" : product?.beer_style?.name, "color": "text-success"}}),
                 ...(productValidation?.spirit_type && { "Type de spirit": {"value" : product?.spirit_type?.name, "color": "text-success"}}),
                 ...(productValidation?.spirit_subtype && { "Sous-Type de spirit": {"value" : product?.spirit_subtype?.name, "color": "text-success"}}),
                 ...(productValidation?.multi_product_country && { "Pays": {"value" : product?.multi_product_country?.name, "color": "text-success"}}),
                 ...(productValidation?.multi_product_region && { "Region": {"value" : product?.multi_product_region?.name, "color": "text-success"}}),
                 ...(otherInfo?.Bieres?.descriptionBitterness && { "Description amertume": {"value" : product.card.description_bitterness, "color": "text-success"}}),
                 ...(otherInfo?.Bieres?.fermentation && { "Fermentation": {"value" : product?.card?.fermentation, "color": "text-success"}}),
                 ...(otherInfo?.Bieres?.glutenFree && { "Sans gluten": {"value" : product?.card?.gluten_free, "color": "text-success"}}),
                 ...(otherInfo?.Bieres?.ibu && { "IBU": {"value" : product?.card?.ibu, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.age && { "Age": {"value" : product?.card?.age?.name, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.agingType && { "Type de vieillissement": {"value" : product?.card?.aging_type, "color":  "text-success"}}),
                 ...(otherInfo?.Spiritueux?.bottler && { "Embouteilleur": {"value" : product?.card?.bottler, "color":  "text-success"}}),
                 ...(otherInfo?.Spiritueux?.caneVariety && { "Variete de canne": {"value" : product?.card?.cane_variety, "color":  "text-success"}}),
                 ...(otherInfo?.Spiritueux?.consumptionType && { "Type de consommation": {"value" : product?.card?.consumption_type, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.distillationType && { "Type de distillation": {"value" : product?.card?.distillation_type, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.finale && { "Finale": {"value" : product?.card?.final, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.fullProof && { "Full Proof": {"value" : product?.card?.full_proof, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.peat && { "Tourbe": {"value" : product?.card?.peat?.name, "color": "text-success"}}),
                 ...(otherInfo?.Spiritueux?.singleCask && { "Single Cask": {"value" : product?.card?.single_cask, "color": "text-success"}}),
            }

            if(product?.card?.attributes) {
                product?.card?.attributes.map(attribute => {
                    desc[attribute.generic_attribute_system.name] = {"value" : attribute.value, "color": "text-success"};
                    return null;
                });
            }

        }

        let status;

        let backgroundHeader;
        if(this.props.status === "pending" || this.props.status === "pre_match") {
            backgroundHeader = "headerProductImport mb-2 bg-light";
            status = <Pending handleChangeStatus={this.props.handleChangeStatus} handleChangeProduct={this.props.handleChangeProduct} config={this.props.config} productValidation={this.props.productValidation} description={desc} handlerMultiproduct={this.handlerMultiproduct} multiproduct={this.state.multiproduct} token={this.props.token}/>
        } else if(this.props.status === "pass") {
            backgroundHeader = "headerProductImport mb-2 bg-warning";
            status = <Pending handleChangeStatus={this.props.handleChangeStatus} handleChangeProduct={this.props.handleChangeProduct} config={this.props.config} productValidation={this.props.productValidation} description={desc} handlerMultiproduct={this.handlerMultiproduct} multiproduct={this.state.multiproduct} token={this.props.token}/>
        } else if(this.props.status === "unmatched" || this.props.status === "refused") {
            backgroundHeader = "headerProductImport mb-2 text-white bg-danger";
            status = <Dematch status={this.props.status} handleChangeProduct={this.props.handleChangeProduct} handleChangeProductValidation={this.props.handleChangeProductValidation} handleChangeStatus={this.props.handleChangeStatus} productValidation={this.props.productValidation} handlerMultiproduct={this.handlerMultiproduct} description={desc} product={product} token={this.props.token}/>;
        } else {
            backgroundHeader = "headerProductImport mb-2 text-white bg-success";
            status = <Match handleChangeProduct={this.props.handleChangeProduct}  status={this.props.status} handleChangeStatus={this.props.handleChangeStatus} productValidation={this.props.productValidation} description={desc} product={product} token={this.props.token}/>;
        }

        return (
            <>
                <Row>
                    <Col xs={12}  className={backgroundHeader}>
                        <HeaderProductImport product={product.id} cuvee={multiproductId} status={this.props.productValidation.status} type={productType}/>
                    </Col>
                    {status}

                </Row>
            </>
        );
    }
}

class ProductImported extends React.Component {

    checkColors(property1,property2) {
        return (property1 === property2) ? "text-success" : "text-danger";
    }

    render() {
        const imgsSRC = [this.props.productValidation.label_url, this.props.productValidation.cover_url];
        const imgs = [];
        const { productValidation } = this.props;
        const { product } = this.props;
        const { config } = this.props;

        const otherInfo = JSON.parse(productValidation.other_info);

        let categoryDefault = productValidation.category_code;
        config?.categories.forEach(category => {
            if (category.mirakl_id === productValidation.category_code) {
                categoryDefault = category.name;
            }
        });

        let desc = {
            ...(productValidation?.product_identifier && { "Shop SKU": {"value" : productValidation.product_identifier, "color": "text-success"}}),
            ...(productValidation?.category_code && { "Categorie": {"value" : categoryDefault, "color": "text-success"}}),
            ...(productValidation?.name && { "Nom": {"value" : productValidation.name, "color": (product.name) ? this.checkColors(productValidation.name, product.name) : ""}}),
            ...(productValidation?.producer && { "Domaine": {"value" : productValidation.producer.name, "color": (product?.producer) ? this.checkColors(productValidation.producer.name, product.producer.name) : ""}}),
            ...(productValidation?.producer_txt && { "Domaine txt": {"value" : productValidation.producer_txt, "color": (product?.producer) ? this.checkColors(productValidation.producer_txt, product?.producer.name) : ""}}),
            ...(productValidation?.appellation && { "Appellation": {"value" : productValidation.appellation?.name, "color": (product?.appellation) ? this.checkColors(productValidation.appellation.name, product?.appellation.name) : ""}}),
            ...(productValidation?.appellation_txt && { "Appellation txt": {"value" : productValidation.appellation_txt, "color": (product?.appellation) ? this.checkColors(productValidation.appellation_txt, product?.appellation.name) : ""}}),
            ...(productValidation?.color && { "Couleur": {"value" : productValidation.color.name, "color": (product?.color) ? this.checkColors(productValidation.color.name, product?.color.name) : ""}}),
            ...(productValidation?.vintage && { "Millesime": {"value" : productValidation.vintage.name, "color": (product?.vintage) ? this.checkColors(productValidation.vintage.name, product?.vintage.name) : ""}}),
            ...(productValidation?.capacity && { "Capacite": {"value" : productValidation.capacity.name, "color": (product?.capacity) ? this.checkColors(productValidation.capacity.name, product?.capacity.name) : ""}}),
            ...(productValidation?.serving_temperature && { "Temperature": {"value" : productValidation.serving_temperature, "color": (product?.card) ? this.checkColors(productValidation.serving_temperature, product?.card.serving_temperature) : ""}}),
            ...(productValidation?.decanting && { "Carafage": {"value" : productValidation.decanting, "color": (product?.card) ? this.checkColors(productValidation.decanting, product.card.decanting) : ""}}),
            ...(productValidation?.garde && { "Garde": {"value" : productValidation.garde, "color": (product?.card) ? this.checkColors(productValidation.garde, product.card.garde) : ""}}),
            ...(productValidation?.apogee && { "Apogee": {"value" : productValidation.apogee, "color": (product?.card) ? this.checkColors(productValidation.apogee, product.card.apogee) : ""}}),
            ...(productValidation?.terroir && { "Terroir": {"value" : productValidation.terroir, "color": (product?.card) ? this.checkColors(productValidation.terroir, product.card.terroir) : ""}}),
            ...(productValidation?.type_culture && { "Type de culture": {"value" : productValidation.type_culture.name, "color": (product?.card?.type_culture) ? this.checkColors(productValidation.type_culture.name, product.card.type_culture.name) : ""}}),
            ...(productValidation?.sulfites && { "Sulfites": {"value" : productValidation.sulfites, "color": (product?.card) ? this.checkColors(productValidation.sulfites, product.card.sulfites) : ""}}),
            ...(productValidation?.sugar_rate && { "Taux de sucre": {"value" : productValidation.sugar_rate, "color": (product?.card) ? this.checkColors(productValidation.sugar_rate, product.card.sugar_rate) : ""}}),
            ...(productValidation?.alcohol_rate && { "Taux d'alcool": {"value" : productValidation.alcohol_rate, "color": (product?.card) ? this.checkColors(productValidation.alcohol_rate, product.card.alcohol) : ""}}),
            ...(productValidation?.grapes1 && { "Cepages": {"value" : productValidation.grapes1.name, "color": (product?.card) ? this.checkColors(productValidation.grapes1, product.card.grapes) : ""}}),
            ...(productValidation?.description && { "Description": {"value" : productValidation.description, "color": (product?.card) ? this.checkColors(productValidation.description, product.card.description) : ""}}),
            ...(productValidation?.eye_description && { "Description oeil": {"value" : productValidation.eye_description, "color": (product?.card) ? this.checkColors(productValidation.eye_description, product.card.eye_description) : ""}}),
            ...(productValidation?.nose_description && { "Description nez": {"value" : productValidation.nose_description, "color": (product?.card) ? this.checkColors(productValidation.nose_description, product.card.nose_description) : ""}}),
            ...(productValidation?.mouth_description && { "Description bouche": {"value" : productValidation.mouth_description, "color": (product?.card) ? this.checkColors(productValidation.mouth_description, product.card.mouth_description) : ""}}),
            ...(productValidation?.mouth_attribute1 && { "Attribut de bouche": {"value" : productValidation.mouth_attribute1.name, "color": (product?.card) ? this.checkColors(productValidation.mouth_attribute1, product.card.mouth_attributes) : ""}}),
            ...(productValidation?.nose_attribute1 && { "Attribut de nez": {"value" : productValidation.nose_attribute1.name, "color": (product?.card) ? this.checkColors(productValidation.nose_attribute1, product.card.nose_attributes) : ""}}),
            ...(productValidation?.food_pairing_appetizer && { "Accords Mets/Vins Entree": {"value" : productValidation.food_pairing_appetizer, "color": (product?.foodparing) ? this.checkColors(productValidation.food_pairing_appetizer, product.foodparing.entree) : ""}}),
            ...(productValidation?.food_pairing_meat && { "Accords Mets/Vins Viande": {"value" : productValidation.food_pairing_meat, "color": (product?.foodparing) ? this.checkColors(productValidation.food_pairing_meat, product.foodparing.meat) : ""}}),
            ...(productValidation?.food_pairing_fish && { "Accords Mets/Vins Poisson": {"value" : productValidation.food_pairing_fish, "color": (product?.foodparing) ? this.checkColors(productValidation.food_pairing_fish, product.foodparing.fish) : ""}}),
            ...(productValidation?.food_pairing_vegetable && { "Accords Mets/Vins Legumes": {"value" : productValidation.food_pairing_vegetable, "color": (product?.foodparing) ? this.checkColors(productValidation.food_pairing_vegetable, product.foodparing.vegetables) : ""}}),
            ...(productValidation?.food_pairing_cheese && { "Accords Mets/Vins Fromage": {"value" : productValidation.food_pairing_cheese, "color": (product?.foodparing) ? this.checkColors(productValidation.food_pairing_cheese, product.foodparing.cheese) : ""}}),
            ...(productValidation?.food_pairing_dessert && { "Accords Mets/Vins Dessert": {"value" : productValidation.food_pairing_dessert, "color": (product?.foodparing) ? this.checkColors(productValidation.food_pairing_dessert, product.foodparing.dessert) : ""}}),
            ...(productValidation?.beer_type && { "Type de biere": {"value" : productValidation.beer_type.name, "color": (product?.beer_type) ? this.checkColors(productValidation.beer_type.id, product.beer_type.id) : ""}}),
            ...(productValidation?.beer_style && { "Style de biere": {"value" : productValidation.beer_style.name, "color": (product?.beer_style) ? this.checkColors(productValidation.beer_style.id, product.beer_style.id) : ""}}),
            ...(productValidation?.spirit_type && { "Type de spirit": {"value" : productValidation.spirit_type.name, "color": (product?.spirit_type) ? this.checkColors(productValidation.spirit_type.id, product.spirit_type.id) : ""}}),
            ...(productValidation?.spirit_subtype && { "Sous-Type de spirit": {"value" : productValidation.spirit_subtype.name, "color": (product?.spirit_subtype) ? this.checkColors(productValidation.spirit_subtype.id, product.spirit_subtype.id) : ""}}),
            ...(productValidation?.multi_product_country && { "Pays": {"value" : productValidation.multi_product_country.name, "color": (product?.multi_product_country) ? this.checkColors(productValidation.multi_product_country.id, product.multi_product_country.id) : ""}}),
            ...(productValidation?.multi_product_region && { "Region": {"value" : productValidation.multi_product_region.name, "color": (product?.multi_product_region) ? this.checkColors(productValidation.multi_product_region.id, product.multi_product_region.id) : ""}}),
            ...(otherInfo?.Bieres?.descriptionBitterness && { "Description amertume": {"value" : otherInfo.Bieres.descriptionBitterness, "color": (product?.card?.description_bitterness) ? this.checkColors(otherInfo.Bieres.descriptionBitterness, product.card.description_bitterness) : ""}}),
            ...(otherInfo?.Bieres?.fermentation && { "Fermentation": {"value" : otherInfo.Bieres.fermentation, "color": (product?.card?.fermentation) ? this.checkColors(otherInfo.Bieres.fermentation, product.card.fermentation) : ""}}),
            ...(otherInfo?.Bieres?.glutenFree && { "Sans gluten": {"value" : otherInfo.Bieres.glutenFree, "color": (product?.card?.gluten_free) ? this.checkColors(otherInfo.Bieres.glutenFree, product.card.gluten_free) : ""}}),
            ...(otherInfo?.Bieres?.ibu && { "IBU": {"value" : otherInfo.Bieres.ibu, "color": (product?.card?.ibu) ? this.checkColors(otherInfo.Bieres.ibu, product.card.ibu) : ""}}),
            ...(otherInfo?.Spiritueux?.age && { "Age": {"value" : otherInfo.Spiritueux.age.name, "color": (product?.card?.age) ? this.checkColors(otherInfo.Spiritueux.age.name, product.card.age.name) : ""}}),
            ...(otherInfo?.Spiritueux?.agingType && { "Type de vieillissement": {"value" : otherInfo.Spiritueux.agingType, "color": (product?.card?.aging_type) ? this.checkColors(otherInfo.Spiritueux.agingType, product.card.aging_type) : ""}}),
            ...(otherInfo?.Spiritueux?.bottler && { "Embouteilleur": {"value" : otherInfo.Spiritueux.bottler, "color": (product?.card?.bottler) ? this.checkColors(otherInfo.Spiritueux.bottler, product.card.bottler) : ""}}),
            ...(otherInfo?.Spiritueux?.caneVariety && { "Variete de canne": {"value" : otherInfo.Spiritueux.caneVariety, "color": (product?.card?.cane_variety) ? this.checkColors(otherInfo.Spiritueux.caneVariety, product.card.cane_variety) : ""}}),
            ...(otherInfo?.Spiritueux?.consumptionType && { "Type de consommation": {"value" : otherInfo.Spiritueux.consumptionType, "color": (product?.card?.consumption_type) ? this.checkColors(otherInfo.Spiritueux.consumptionType, product.card.consumption_type) : ""}}),
            ...(otherInfo?.Spiritueux?.distillationType && { "Type de distillation": {"value" : otherInfo.Spiritueux.distillationType, "color": (product?.card?.distillation_type) ? this.checkColors(otherInfo.Spiritueux.distillationType, product.card.distillation_type) : ""}}),
            ...(otherInfo?.Spiritueux?.finale && { "Finale": {"value" : otherInfo.Spiritueux.finale, "color": (product?.card?.finale) ? this.checkColors(otherInfo.Spiritueux.finale, product.card.finale) : ""}}),
            ...(otherInfo?.Spiritueux?.fullProof && { "Full Proof": {"value" : otherInfo.Spiritueux.fullProof, "color": (product?.card?.full_proof) ? this.checkColors(otherInfo.Spiritueux.fullProof, product.card.full_proof) : ""}}),
            ...(otherInfo?.Spiritueux?.peat && { "Tourbe": {"value" : otherInfo.Spiritueux.peat.name, "color": (product?.card?.peat) ? this.checkColors(otherInfo.Spiritueux.peat.name, product.card.peat.name) : ""}}),
            ...(otherInfo?.Spiritueux?.singleCask && { "Single Cask": {"value" : otherInfo.Spiritueux.singleCask, "color": (product?.card?.single_cask) ? this.checkColors(otherInfo.Spiritueux.singleCask, product.card.single_cask) : ""}}),
        };

        if(otherInfo?.Generiques) {
            for (const [label, value] of Object.entries(otherInfo.Generiques)) {
                desc[label] = {"value" : value, "color": ""};
            }
        }

        imgsSRC.forEach((img, index) => {
            if(img) {
                imgs.push(<ProductImage
                        src={img}
                        key={index}
                    />
                );
            }
        });

        let backgroundHeader;
        if(this.props.status === "pending" || this.props.status === "pre_match") {
            backgroundHeader = "headerProductImport mb-2 bg-light";
        } else if(this.props.status === "pass") {
            backgroundHeader = "headerProductImport mb-2 bg-warning";
        }  else if(this.props.status === "unmatched" || this.props.status === "refused") {
            backgroundHeader = "headerProductImport mb-2 text-white bg-danger";
        } else {
            backgroundHeader = "headerProductImport mb-2 text-white bg-success";
        }

        return (
            <Row>
                <Col xs={12} className={backgroundHeader}>
                    <HeaderProductImport name={this.props.productValidation.filename} id={this.props.productValidation.id} status={this.props.productValidation.status}/>
                </Col>
                <Col xs={4}>
                    <div className={"imgs"}>
                        {imgs}
                    </div>
                </Col>
                <Col xs={8}>
                    <ProductDescription description={desc}/>
                </Col>
            </Row>
        );
    }
}

class ProductImportRow  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: this.props.status,
            productValidation : this.props.productValidation,
            product : (this.props.productValidation.matched_product) ? this.props.productValidation.matched_product : {},
            hidden : false
        }
    }

    handleChangeStatus = (status) => {
        this.setState({
            status: status,
            hidden: status === "pass"
        })
    }

    handleChangeProductValidation = (productValidation) => {
        this.setState({
            productValidation: productValidation
        })
    }

    handleChangeProduct = (product) => {
        this.setState({
            product: product
        })
    }

    render() {
        let row;
        if(this.state.hidden) {
            row = "";
        } else {
            row = (
                <>
                    <Row className={"ProductImportRow"}>
                        <Col sm={6} className={"right"}>
                            <ProductImported status={this.state.status} productValidation={this.state.productValidation} product={this.state.product} config={this.props.config}/>
                        </Col>
                        <Col sm={6}>
                            <CuveeTwil status={this.state.status} handleChangeProductValidation={this.handleChangeProductValidation} handleChangeStatus={this.handleChangeStatus} handleChangeProduct={this.handleChangeProduct} product={this.state.product} token={this.props.token} productValidation={this.state.productValidation} config={this.props.config}/>
                        </Col>
                    </Row>
                </>
            )
        }

        return(
            <>
                {row}
            </>
        );
    }
}


class ProductValidation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productValidation: [],
            page: 1,
            loading: false,
            prevY: 0,
        };
    }

    fetchProducts() {
        try {
            this.setState({ loading: true });
            const params = this.props.filter;
            params['status'] = this.props.status;
            params['page'] = this.state.page;
            params['limit'] = 5;
            const url = process.env.REACT_APP_SF_URL + "api/1/fr_fr/productvalidation?" + new URLSearchParams(params).toString()
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    "host" : "dev-twil.co",
                    'Authorization': 'Bearer ' + this.props.token,
                }})
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result?._embedded?.items) {
                            this.setState({
                                productValidation: [...this.state.productValidation, ...result?._embedded.items],
                                loading : false
                            });
                        } else if(result?.error !== "invalid_grant") {
                            this.fetchProducts();
                        }
                    },
                    // Remarque : il est important de traiter les erreurs ici
                    // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
                    // des exceptions provenant de réels bugs du composant.
                    (error) => {
                        console.log("error");
                        console.log(error);
                    }
                )
        } catch (err) {
            console.log(err);
            if (err.error !== 'login_required') console.log(err.error);
        }
    }

    async componentDidMount() {
        this.fetchProducts();
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
           };

            this.observer = new IntersectionObserver(
                this.handleObserver.bind(this),
                options
            );
            this.observer.observe(this.loadingRef);
    }


    //Infinite scroll
    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y) {
            this.setState((prevState, props) => ({
                page: prevState.page + 1
            }));
            this.fetchProducts();
        }
        this.setState({ prevY: y });
    }

    componentDidUpdate(prevProps) {
        if((prevProps.status !== this.props.status) || (prevProps.filter !== this.props.filter)) {
            this.setState({
                productValidation: [],
                page: 1,
                prevY: 0,
            }, () => {
                this.fetchProducts()
            });

        }
    }

    render() {
        const list = [];
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        this.state.productValidation.forEach((product, index) => {
            list.push(<ProductImportRow
                    productValidation={product}
                    key={product.id}
                    token={this.props.token}
                    config={this.props.config}
                    status={this.props.status}
                />
            );
        });

        return(
            <>
                <Container className={"page"}>

                    { list }
                    <div ref={loadingRef => (this.loadingRef = loadingRef)}>
                        <span style={loadingTextCSS} className={"text-center"}>
                            <Spinner animation="grow" variant="primary" />
                            <Spinner animation="grow" variant="primary" />
                            <Spinner animation="grow" variant="primary" />
                        </span>
                    </div>
                </Container>
            </>
        );
    }
}

export default ProductValidation;