import { useState } from 'react';

export default function useConfig() {
    const getConfig = () => {
        const configString = localStorage.getItem('config');
        const config = JSON.parse(configString);
        return config;
    };

    const [config, setConfig] = useState(getConfig());

    const saveConfig = config => {
        localStorage.setItem('config', JSON.stringify(config));
        setConfig(config);
    };

    return {
        setConfig: saveConfig,
        config
    }
}