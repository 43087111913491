import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import Select from "react-select";

class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: this.props.filter
        }

    }

    handleCheckboxChange = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                "groupby" : e.target.checked
            }
        }));
    }

    handleFilterTextChange = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [e.target.id] : e.target.value
            }
        }));
    }

    handleFilterColorChange = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                "color" : e.value
            }
        }));
    }

    handleFilterTypeChange = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                "type" : e.value
            }
        }));
    }

    handleFilterShopChange = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                "shopId" : e.value
            }
        }));
    }

    handleInputChange = (event) => {
        if(event) {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    "shopId" : event
                }
            }));
        }
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.searchFilter(this.state.filter);
        this.props.closeFilter();
        this.props.fetchCount(this.state.filter);
    }

    render() {

        const colors = [];
        this.props.colors.forEach(color =>
            colors.push({value: color.id, label: color.name})
        )

        const types = [];
        this.props.types.forEach(type =>
            types.push({value: type.id, label: type.name})
        )

        const shopsPending = [];
        this.props.shopsPending?.forEach(shop =>
            shopsPending.push({value: shop, label: shop})
        )

        return (
            <Form>
                <Form.Group className="mb-3" controlId="cuvee">
                    <Form.Label>Affichage par cuvée</Form.Label>
                    <Form.Check type="checkbox" checked={this.state.filter.groupby} onChange={this.handleCheckboxChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>Type de produit</Form.Label>
                    <Select options={types} onChange={this.handleFilterTypeChange} onKeyDown={this.handleKeyDown} placeholder={this.state.filter?.type ? this.state.filter.type : ""}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="productIdentifier">
                    <Form.Label>Shop SKU</Form.Label>
                    <Form.Control type="input" placeholder="Shop Sku" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control type="input" value={this.state.filter?.name ? this.state.filter.name : ""} placeholder="Nom" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="color">
                    <Form.Label>Couleur</Form.Label>
                    <Select options={colors} onChange={this.handleFilterColorChange} onKeyDown={this.handleKeyDown} placeholder={this.state.filter?.color ? this.state.filter.color : ""}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="shopId">
                    <Form.Label>Shop Id</Form.Label>
                    <Select options={shopsPending} onInputChange={this.handleInputChange} onChange={this.handleFilterShopChange} onKeyDown={this.handleKeyDown} placeholder={this.state.filter?.shopId ? this.state.filter.shopId : ""}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="filename">
                    <Form.Label>FileName</Form.Label>
                    <Form.Control type="input" value={this.state.filter?.filename ? this.state.filter.filename : ""} placeholder="FileName" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="producer_txt">
                    <Form.Label>Producer</Form.Label>
                    <Form.Control type="input" value={this.state.filter?.producer_txt ? this.state.filter.producer_txt : ""} placeholder="Producer" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="appellation_txt">
                    <Form.Label>Appellation</Form.Label>
                    <Form.Control type="input" value={this.state.filter?.appellation_txt ? this.state.filter.appellation_txt : ""} placeholder="Appellation" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="gtin">
                    <Form.Label>GTIN</Form.Label>
                    <Form.Control type="input" value={this.state.filter?.gtin ? this.state.filter.gtin : ""} placeholder="GTIN" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="region">
                    <Form.Label>Region</Form.Label>
                    <Form.Control type="input" value={this.state.filter?.region ? this.state.filter.region : ""} placeholder="Region" onChange={this.handleFilterTextChange} onKeyDown={this.handleKeyDown} />
                </Form.Group>
                <Button variant="primary" type="button" onClick={this.handleSubmit}>
                    Filtrer
                </Button>
            </Form>
        );
    }
}

export default Filter;