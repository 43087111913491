import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Login.css';
import {Button, Form, Spinner, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

async function loginUser(credentials) {
    try {
        return fetch(process.env.REACT_APP_SF_URL + 'oauth/v2/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "host" : "dev-twil.co"
            },
            body: JSON.stringify(credentials)
        }).then(data => data.json())
    } catch (err) {
        console.log(err);
        if (err.error !== 'login_required') console.log(err.error);
    }
}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loader, setLoader] = useState();
    const [error, setError] = useState();


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('access_token') !== undefined && urlParams.get('access_token') !== null) {
        var urlToken = {
            'access_token' : urlParams.get('access_token'),
            'refresh_token' : urlParams.get('refresh_token')
        }
        setToken(urlToken);
    }

    const handleSubmit = async e => {
        setError('');
        setLoader(<Spinner animation="grow" variant="primary" />);
        e.preventDefault();
        const client_id = process.env.REACT_APP_CLIENT_ID;
        const client_secret = process.env.REACT_APP_CLIENT_SECRET;
        const grant_type = "password";
        const token = await loginUser({
            client_id,
            client_secret,
            grant_type,
            username,
            password
        });
        setLoader('');
        if(token.hasOwnProperty('access_token')) {
            setToken(token);
            window.location.href = '/';
        } else {
            setError(token.error_description);
        }
    }

    return(
        <div className="login-wrapper">
            <h1 className={"mt-5"}>TWIL</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={e => setUserName(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Connexion
                </Button>
            </Form>
            <Col className={"mt-4 text-danger"}>
                {loader}
                {error}
            </Col>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};