import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken.access_token));
        localStorage.setItem('refresh_token', JSON.stringify(userToken.refresh_token));
        setToken(userToken.access_token);
    };

    return {
        setToken: saveToken,
        token
    }
}